import request from "@/utils/request";

export function queryOrder(page, size, data) {
  return request({
    url: "/sys/orderInfo/queryOrders?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addOrder(data) {
  return request({
    url: "/sys/orderInfo/addOrder",
    method: "post",
    data,
  });
}

export function getOrder(id) {
  return request({
    url: "/sys/orderInfo/getOneOrder/" + id,
    method: "get",
  });
}

export function updateOrder(data) {
  return request({
    url: "/sys/orderInfo/updateOrder",
    method: "post",
    data,
  });
}

export function deleteOrder(data) {
  return request({
    url: "/sys/orderInfo/deleteOrders",
    method: "post",
    data,
  });
}

export function statistic() {
  return request({
    url: "/sys/orderInfo/statistic",
    method: "get",
  });
}
//上传照片
export function uploadPhoto(data) {
  return request({
    url: "/sys/orderInfo/uploadPhoto",
    method: "post",
    data,
  });
}
//重传照片
export function reUploadPhoto(data) {
  return request({
    url: "/sys/orderInfo/reUploadPhoto",
    method: "post",
    data,
  });
}

export function cencelPay(data) {
  return request({
    url: "/sys/orderInfo/cencelPay",
    method: "post",
    data,
  });
}

export function getPhoto(id, headers = {}) {
  return request({
    url: "/file/download/" + id,
    method: "get",
    responseType: "blob",
    timeout: 180000,
    headers,
  });
}

export function queryOrderByEducationId(id) {
  return request({
    url: "/sys/orderInfo/queryOrderByEducationId/" + id,
    method: "get",
  });
}

export function getRegionsByParentId(id) {
  return request({
    url: "/sys/RegionsInfo/getRegionsByParentId/" + id,
    method: "get",
  });
}

export function updateMailinfo(data) {
  return request({
    url: "/sys/orderInfo/updateMailinfo",
    method: "post",
    data,
  });
}
//查询人工识别订单列表接口
export function getFailedOrders(page = 1, size = 20, data) {
  return request({
    url: "/sys/orderInfo/query/FailedOrders?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}
//查询人工识别订单列表接口
export function getOrderFlowByOrderNo(order = "") {
  return request({
    url: "/sys/orderInfo/getOrderFlowByOrderNo/" + order,
    method: "get",
  });
}
//忽略人脸识别上传
export function getIgnorePhoto(id) {
  return request({
    url: `/sys/orderInfo/ignorePhoto?id=${id}`,
    method: "post",
  });
}
/**
 * 查看订单日志
 * @param {*} id
 * @returns
 */
export function getOrdersLogs(id) {
  return request({
    url: `/sys/orderInfo/getLogs/${id}`,
    method: "get",
  });
}

//查询扣款失败订单列表
export function getFailedPay(page = 1, size = 20, data) {
  return request({
    url: "/sys/orderInfo/query/FailedPay?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

//扣款失败订单---取消订单接口
export function cencelPaySupplement(data) {
  return request({
    url: `/sys/orderInfo/cencelPay/Supplement`,
    method: "post",
    data,
  });
}
/**
 * 查询E钱包开通数据
 * @param {*} data
 */
export function epayStatistics(data) {
  return request({
    url: `/sys/orderInfo/epayStatistics`,
    method: "post",
    data,
  });
}

//批量替换照片---列表(校拍)
export function getReplacePhotos(currentPage = 1, pageSize = 20, data) {
  return request({
    url:
      "/sys/taskInfo/replacePhotos?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

// //批量替换照片---查看处理进度(校拍)
// export function getqueryTasks(currentPage = 1, pageSize = 20, data) {
//   return request({
//     url: "/sys/replacePhotoTask/query/tasks?currentPage=" + currentPage + "&pageSize=" + pageSize,
//     method: "post",
//     data,
//   });
// }
//校拍批量下载
export function getDownloadReplace(taskId) {
  return request({
    url: "/sys/taskInfo/downloadReplace?taskId=" + taskId,
    method: "get",
    taskId,
  });
}

//批量替换照片---列表(散拍)
export function getReplacePhotosSP(currentPage = 1, pageSize = 20, data) {
  return request({
    url:
      "/sys/orderInfo/query/ReplacePhotos?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}
//批量替换散拍---创建替换任务
export function replacePhotoTaskAdd(data = {}) {
  return request({
    url: `/sys/replacePhotoTask/add`,
    method: "post",
    data,
  });
}

//批量替换照片---列表(散拍)、、//批量替换照片---查看处理进度(校拍)

export function getQueryTasks(currentPage = 1, pageSize = 20, data) {
  return request({
    url:
      "/sys/replacePhotoTask/query/tasks?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

//散拍批量下载
export function getDownloadReplaceSp(id) {
  return request({
    url: "/sys/orderInfo/downloadReplace?id=" + id,
    method: "get",
    id,
  });
}

//批量替换照片---查看明细(散拍)
export function getQueryinfos(currentPage = 1, pageSize = 20, data) {
  return request({
    url:
      "/sys/replacePhotoTask/query/infos?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

//批量替换照片---查看失败学生(散拍)
export function getQueryreplaceFail(currentPage = 1, pageSize = 20, data) {
  return request({
    url:
      "/sys/replacePhotoTask/query/replaceFail?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

//散拍批量下载
export function getFailReplace(id) {
  return request({
    url: "/sys/replacePhotoTask/download/FailReplace?id=" + id,
    method: "get",
    id,
  });
}

//批量替换---上传替换照片
export function uploadFile(data) {
  return request({
    url: `/sys/replacePhotoTask/uploadFile`,
    method: "post",
    data,
  });
}

//批量替换---获取上传复核列表
export function photoDetail(id) {
  return request({
    url: "/sys/replacePhotoTask/photoDetail?id=" + id,
    method: "get",
    id,
  });
}

//批量替换---是否确认替换
export function checkReplace(data) {
  return request({
    url: "/sys/replacePhotoTask/checkReplace",
    method: "post",
    data,
  });
}

//批量替换照片---查看处理进度(散拍)
export function getCheckProgress(id) {
  return request({
    url: "/sys/replacePhotoTask/checkProgress?id=" + id,
    method: "get",
    id,
  });
}
// 已处理完成pots请求
export function setPhtotHandle(taskId) {
  return request({
    url: `/sys/replacePhotoTask/isHandle/${taskId}`,
    method: "post",
  });
}

//散拍物流接口
export function getLogistics(traceNo) {
  return request({
    url: "/sys/ems/mail/logistics?traceNo=" + traceNo,
    method: "get",
    traceNo,
  });
}

//分片上传
export function minioUpload(data) {
  return request({
    url: "/sys/minio/upload",
    headers: {
      "Content-Type": "application/json",
    },
    processData: false,
    contentType: false,
    method: "post",
    data,
  });
}

// 合并分片
export function merge(data) {
  return request({
    url: "/sys/minio/merge",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 合并结尾
export function mergeEnd(data) {
  return request({
    url: "/sys/replacePhotoTask/uploadFile",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

// 合并结尾
export function uploadFileBase64un(params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    http
      .post(api.files_base64_un, params, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//创建散拍任务
export function casualPhotoTaskAdd(data = {}) {
  return request({
    url: "/sys/casualPhotoTask/add",
    method: "post",
    data,
  });
}

//选择订单——散拍
export function queryOrders(data = {}, currentPage, pageSize) {
  return request({
    url:
      "/sys/orderInfo/query/orders?currentPage=" +
      currentPage +
      "&pageSize=" +
      pageSize,
    method: "post",
    data,
  });
}

/**
 * 强制取消订单（已完成的订单）
 * @param {*}
 * @returns
 */
export function forceCancelPay(data) {
  return request({
    url: "/sys/orderInfo/forceCancelPay",
    method: "post",
    data,
  });
}

/**
 * 散拍列表上传采集码
 * @param {*}
 * @returns
 */
export function uploadCjm(data) {
  return request({
    url: "/sys/orderInfo/uploadCjm",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data,
  });
}

//创建散拍任务--时间回退一周提示
export function ordersCount(data) {
  return request({
    url: "/sys/orderInfo/query/ordersCount",
    method: "post",
    data,
  });
}
//查询采集支付订单
export function getOrderByCjm(data) {
  return request({
    url: "/sys/orderInfo/getOrderByCjm",
    method: "post",
    data,
  });
}
