<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="7vh"
  >
    <el-form>
      <el-form-item label="请选择取消原因">
        <el-radio-group
          v-model="cancelId"
          style="display: flex; flex-wrap: wrap; padding-top: 4px"
        >
          <el-radio
            :label="item.id"
            v-for="item in cancelList"
            :key="item.id"
            :value="item.id"
            style="margin-top: 8px"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" :loading="confirmLoading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { queryCancels, cencelOrder } from "@/api/appreciation";
export default {
  name: "cancelTips",
  props: {
    title: {
      type: String,
      default: "取消订单",
    },
    width: {
      type: String,
      default: "600px",
    },
  },
  computed: {},
  data() {
    return {
      confirmLoading: false,
      visible: false,
      cancelId: "",
      id: 0,
      cancelList: [],
    };
  },
  methods: {
    initData(id) {
      this.id = id;
      this.visible = true;
      queryCancels().then((res) => {
        if (res.code == 0) {
          this.cancelList = res.data;
        }
      });
    },
    handleConfirm() {
      this.confirmLoading = true;
      if (!this.cancelId) {
        this.$message({
          message: "请选择取消原因",
        });
        this.confirmLoading = false;
        return false;
      }
      cencelOrder({ id: this.id, cancelId: this.cancelId }).then((res) => {
        if (res.code == 0) {
          this.confirmLoading = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.visible = false;
          this.$emit("pageReload");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {},
};
</script>
