<template>
  <el-dialog
    title="查看订单详情"
    :visible.sync="visible"
    width="1000px"
    append-to-body
  >
    <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
      <el-form ref="orderForm" :model="orderForm" label-width="80px">
        <div class="tip">订单信息</div>
        <el-row class="rowGray">
          <el-col class="cell" :span="4">
            <label>订单号</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.orderNo || '-'"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>订单状态</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.statusName || '-'"></label>
          </el-col>
        </el-row>
        <el-row class="rowBlank">
          <el-col class="cell" :span="4">
            <label>付款类型</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.payTypeName || '-'"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>商户交易号</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.payId || '-'"></label>
          </el-col>
        </el-row>

        <el-row class="rowGray">
          <el-col class="cell" :span="4">
            <label>支付金额</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.shotPrice || '-'"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>支付时间</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label>{{ formDateTimeFormat(orderForm.refundTime) || "-" }}</label>
          </el-col>
        </el-row>
        <!-- 
          <el-row class="rowBlank">
            <el-col class="cell" :span="4">
              <label>交易号</label>
            </el-col>
            <el-col class="cell" :span="20" style="overflow: hidden">
              <label v-text="orderForm.payId"></label>
            </el-col>
          </el-row> -->
      </el-form>

      <!-- <el-form
          ref="studentForm"
          :model="studentForm"
          label-width="80px"
          style="margin-top: 15px"
        >
          <div class="tip">个人信息</div>
          <el-row class="rowGray">
            <el-col class="cell" :span="4">
              <label>姓名</label>
            </el-col>
            <el-col class="cell" :span="10">
              <label
                ><span>{{ execDecrypt(studentForm.name, "name") }}</span></label
              >
            </el-col>
            <el-col class="cell" :span="4">
              <label>证件号码</label>
            </el-col>
            <el-col class="cell" :span="6">
              {{ execDecrypt(studentForm.idNumber, "Idcard") }}
            </el-col>
          </el-row>
          <el-row class="rowBlank">
            <el-col class="cell" :span="4">
              <label>手机号</label>
            </el-col>
            <el-col class="cell" :span="20">
              {{ execDecrypt(studentForm.mobileNumber, "ph") }}
            </el-col>
          </el-row>
        </el-form> -->
      <el-form
        ref="educationForm"
        :model="educationForm"
        label-width="80px"
        style="margin-top: 15px"
      >
        <div class="tip">学籍信息</div>

        <el-row style="display: flex; align-items: center">
          <el-col :span="20">
            <el-row class="rowGray">
              <el-col class="cell" :span="4">
                <label>姓名</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label
                  ><span>{{
                    execDecrypt(educationForm.name, "name") || "-"
                  }}</span></label
                >
              </el-col>
              <el-col class="cell" :span="4">
                <label>身份证号</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label>
                  {{
                    execDecrypt(educationForm.idNumber, "Idcard") || "-"
                  }}</label
                >
              </el-col>
            </el-row>

            <el-row class="rowBlank">
              <el-col class="cell" :span="4">
                <label>学校名称</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.schoolName || '-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>院校代码</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.schoolCode || '-'"></label>
              </el-col>
            </el-row>
            <el-row class="rowGray">
              <el-col class="cell" :span="4">
                <label>学校类型</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.schoolTypeName || '-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>学历类别</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.xllb || '-'"></label>
              </el-col>
            </el-row>
            <el-row class="rowBlank">
              <el-col class="cell" :span="4">
                <label>班级</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.classes || '-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>分院</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.fy || '-'"></label>
              </el-col>
            </el-row>
            <el-row class="rowGray">
              <el-col class="cell" :span="4">
                <label>专业</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.major || '-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>学号</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.studentCode || '-'"></label>
              </el-col>
            </el-row>
            <el-row class="rowBlank">
              <el-col class="cell" :span="4">
                <label>学历层次</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="educationForm.cc || '-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>毕业时间</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label>{{
                  formDateTimeFormat(educationForm.graduationYear) || "-"
                }}</label>
              </el-col>
            </el-row>
            <el-row class="rowGray">
              <el-col class="cell" :span="4">
                <label>手机号</label>
              </el-col>
              <el-col class="cell" :span="8">
                {{ educationForm.mobileNumber || "-" }}
              </el-col>
              <el-col class="cell" :span="4">
                <label>拍摄序号</label>
              </el-col>
              <el-col class="cell" :span="8">
                {{ educationForm.batchIndex || "-" }}
              </el-col>
            </el-row>
            <!-- <el-row class="rowBlank">
                <el-col class="cell" :span="4">
                  <label>采集码</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.cjmn"></label>
                </el-col>
                <el-col class="cell" :span="4">
                  <label>采集号</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.sjc"></label>
                </el-col>
              </el-row> -->
          </el-col>
          <el-col :span="4" style="text-align: center">
            <el-image :src="photoImg" class="photo">
              <div slot="placeholder" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div>
              <el-link type="primary" v-if="isPhoto" @click="clickDown"
                >照片下载</el-link
              >
            </div>
          </el-col>
        </el-row>
        <!-- <el-row v-else>
            <el-col :span="20">
              <el-row class="rowGray">
                <el-col class="cell" :span="4">
                  <label>学校</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.schoolName"></label>
                </el-col>
                <el-col class="cell" :span="4">
                  <label>学校类别</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.schoolTypeName"></label>
                </el-col>
              </el-row>
              <el-row class="rowBlank">
                <el-col class="cell" :span="4">
                  <label>校区</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.schoolArea"></label>
                </el-col>
                <el-col class="cell" :span="4">
                  <label>院系</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.schoolDept"></label>
                </el-col>
              </el-row>
              <el-row class="rowGray">
                <el-col class="cell" :span="4">
                  <label>专业</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.major"></label>
                </el-col>
                <el-col class="cell" :span="4">
                  <label>学历类别</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.educationType"></label>
                </el-col>
              </el-row>
              <el-row class="rowBlank">
                <el-col class="cell" :span="4">
                  <label>学位</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.educationType"></label>
                </el-col>
                <el-col class="cell" :span="4">
                  <label>学号</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.studentCode"></label>
                </el-col>
              </el-row>
              
              <el-row class="rowGray">
                <el-col class="cell" :span="4">
                  <label>考生号</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.examineeNumber"></label>
                </el-col>
                <el-col class="cell" :span="4">
                  <label>班级</label>
                </el-col>
                <el-col class="cell" :span="8">
                  <label v-text="educationForm.classes"></label>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="4" style="text-align: center">
              <el-image :src="photoImg" class="photo">
                <div slot="placeholder" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div>
                <el-link type="primary" v-if="isPhoto" @click="clickDown"
                  >照片下载</el-link
                >
              </div>
            </el-col>
          </el-row> -->
      </el-form>
      <el-form
        ref="orderForm"
        :model="orderForm"
        label-width="80px"
        style="margin-top: 15px"
      >
        <div class="tip">
          <span v-if="orderForm.status == 2">采集信息</span>
          <span v-else>认证信息</span>
        </div>
        <el-row class="rowGray">
          <el-col class="cell" :span="4">
            <label>采集类型</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.shotTypeName || '-'"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>采集时间</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label>{{
              formDateTimeFormat(orderForm.collectedTime) || "-"
            }}</label>
          </el-col>
          <!-- <el-col class="cell" :span="4">
              <label>状态</label>
            </el-col>
            <el-col class="cell" :span="8">
              <label v-text="orderForm.statusName"></label>
            </el-col> -->
        </el-row>
        <el-row class="rowBlank">
          <el-col class="cell" :span="4">
            <label>采集码</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="educationForm.cjmn || '-'"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>上传批次</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="orderForm.batchName || '-'"></label>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button @click="visible = false">关 闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { dateFormat } from "@/utils/date";
import { getOrder } from "@/api/order";
import { getStudent, getEducation } from "@/api/student";
import { getToken } from "@/utils/auth";
import axios from "axios";
export default {
  name: "orderXpInfo",
  props: {
    width: {
      type: String,
      default: "1000px",
    },
  },
  computed: {},
  data() {
    return {
      visible: false,
      orderForm: {},
      studentForm: {},
      educationForm: {},
      isPhoto: false,
      photoImg: "../temp/none_img.jpg",
    };
  },
  methods: {
    formDateTimeFormat(dateValue, format = "YYYY-mm-dd") {
      if (dateValue != null) {
        return dateFormat(format, new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "name") {
        return formatName(str);
      }
      if (mod == "ph") {
        return formatPh(str);
      }
      if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
            this.isPhoto = true;
            // this.init(this.$refs.wrapper);
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    initData(orderId) {
      this.orderForm = {};
      this.studentForm = {};
      this.educationForm = {};
      this.isPhoto = false;
      this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
      this.visible = true;

      getOrder(orderId)
        .then((resp) => {
          if (resp.data) {
            this.orderForm = resp.data;
            const studentId = resp.data.studentId;
            const educationId = resp.data.educationalId;

            if (
              resp.data.pathCollectedPhoto == null ||
              resp.data.pathCollectedPhoto == ""
            ) {
              this.photoImg =
                "../temp/none_img.jpg?timestamp=" + new Date().getTime();
              this.isPhoto = false;
            } else {
              this.getPhoto(resp.data.pathCollectedPhoto);
            }
            getStudent(studentId)
              .then((resp) => {
                if (resp.data) {
                  this.studentForm = resp.data;
                }
              })
              .catch((error) => {});

            getEducation(educationId)
              .then((resp) => {
                if (resp.data) {
                  this.educationForm = resp.data;
                  this.educationForm.name = this.studentForm.name;
                  this.educationForm.idNumber = this.studentForm.idNumber;
                  this.educationForm.mobileNumber =this.studentForm.mobileNumber;
                  this.educationForm.batchIndex =this.orderForm.batchIndex

                }
              })
              .catch((error) => {});
            this.visible = true;
          }
        })
        .catch((error) => {});
    },
    clickDown() {
      const a = document.createElement("a");
      a.href = this.photoImg;
      console.log(a.href);
      let str =
        // this.studentForm.idNumber +
        // "-" +
        this.studentForm.name +
        "-" +
        this.educationForm.studentCode +
        "-" +
        this.educationForm.schoolName +
        "-" +
        this.educationForm.schoolId +
        ".jpg";
      a.download = str; // 下载文件的名字
      document.body.appendChild(a);
      a.click();
      //在资源下载完成后 清除 占用的缓存资源
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
  },
};
</script>

<style scoped>
.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 40px;
  line-height: 40px;
  vertical-align: bottom;
}

.rowGray .cell {
  background: #f2f2f2;
}

.divice {
  width: 1200px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}
.image-box {
  width: 120px;
  height: 160px;
  margin: auto;
  position: relative;
}
.image-box.line .photo:before {
  content: " ";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #e13333;
  left: 50%;
}
.image-box.line .drag-list {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 30px;
  cursor: all-scroll;
}
.image-box.line .drag-list::before {
  content: " ";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #e13333;
  top: 50%;
  left: 0;
}
.photo {
  width: 120px;
  height: 160px;
  position: relative;
  vertical-align: top;
}
.photo-box .item {
  text-align: center;
}
.photo-box .item .desc {
  line-height: 30px;
  text-align: center;
}
.photo-box .item .photo {
  margin-right: 0;
}
</style>
