<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <!-- 搜索 -->
    <div class="search-box">
      <div class="condition-item">
        <label class="fn-14">订单号</label>
        <el-input
          v-model="search.orderNo"
          placeholder="请输入订单号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">姓名</label>
        <el-input
          v-model="search.studentName"
          placeholder="请输入姓名"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">身份证号</label>
        <el-input
          v-model="search.idNumber"
          placeholder="请输入身份证号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="search.schoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="condition-item">
        <label class="fn-14">拍摄类型</label>
        <el-select
          clearable
          v-model="search.shotTypeCode"
          placeholder="请选择拍摄类型"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
          <el-option :key="0" label="校拍" :value="0"> </el-option>
          <el-option :key="1" label="散拍" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">下单时间</label>
        <el-date-picker
          v-model="search.stime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="search.etime"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集员</label>
        <el-input
          style="width: 130px"
          v-model="search.collectedName"
          placeholder="请输入采集员"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-export"
          @click="handleExport"
          size="small"
          >数据导出
        </el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- 列表数据 -->
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        v-loading="tableLoading"
        :data="dataList"
        stripe
        border
        height="100%"
        style="width: 100%"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column
          prop="orderNo"
          label="订单号"
          min-width="180"
          fixed="left"
        ></el-table-column>
        <el-table-column
          prop="shotTypeCode"
          label="拍摄类型"
          min-width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.shotTypeCode == 0"
              type="danger"
              effect="plain"
              size="small"
              >校拍</el-tag
            >
            <el-tag
              v-else-if="scope.row.shotTypeCode == 1"
              type="success"
              effect="plain"
              size="small"
              >散拍
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="studentName"
          label="姓名"
          min-width="100"
        ></el-table-column>
        <el-table-column prop="idNumber" label="身份证号" min-width="170">
          <template slot-scope="scope">
            <span>{{
              execDecrypt(scope.row.idNumber, "Idcard")
            }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="mobileNumber"
          label="手机号码"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="studentCode"
          label="学号"
          min-width="110"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="下单时间"
          min-width="160"
          :formatter="columnDateFormat"
        ></el-table-column>
        <el-table-column
          prop="collectedName"
          label="采集员"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="订单状态"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 0"
              type="danger"
              effect="plain"
              size="small"
              >待处理</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 1"
              type="success"
              effect="plain"
              size="small"
              >已完成
            </el-tag>
            <el-tag
              v-else-if="scope.row.status == 2"
              type="info"
              effect="plain"
              size="small"
              >已取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="batchName"
          label="操作"
          width="200"
          align="right"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="clickViews(scope.row.orderId, scope.row.shotTypeCode)"
              >查看</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status !== 2"
              @click="clickCancelOrder(scope.row.id)"
              >取消订单</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status === 0"
              @click="clickOk(scope.row)"
              >确认完成</el-button
            >
          </template></el-table-column
        >
      </el-table>
    </div>
    <!-- 列表数据 -->
    <!-- 分页 -->
    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageConfig.currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageConfig.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageConfig.total"
      >
      </el-pagination>
    </div>
    <!-- 分页 -->
    <orderXpInfo ref="orderInfo" :width="'1000px'" />
    <orderSpInfo ref="orderSpInfo" :width="'1000px'" />
    <cancelTips ref="cancelTips" @pageReload="pageReload" />

    <!--    确认完成-->
    <el-dialog
      title="学生照片"
      :visible.sync="dlgImportVisible"
      width="500px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="fileForm"
        ref="fileForm"
        label-width="150px"
        @submit.native.prevent
      >
        <el-form-item label="是否开启人脸识别" required>
          <el-radio-group v-model="fileForm.isFaceDetect">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">不开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传照片：" style="margin-bottom: 0" required>
          <el-row class="photo-box">
            <el-col :span="9" style="margin-right: 20px">
              <div class="item">
                <el-image :src="photoImg" class="photo">
                  <div slot="placeholder" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="desc">原照片</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="item">
                <el-upload
                  class="upload-demo"
                  ref="uploadImport"
                  :multiple="false"
                  action=""
                  :limit="1"
                  :show-file-list="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  accept="image/jpeg,image/png"
                  list-type="picture"
                  :auto-upload="false"
                >
                  <el-image :src="uploadImg" class="photo">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-upload>
                <div class="desc">上传照片</div>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="dlgImportVisible = false">关 闭</el-button>
        <el-button type="primary" :loading="uploadLoading" @click="submitUpload"
          >确认更新</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryOrderList,
  finishOrder,
  exportAppreciationNewOrders,
} from "@/api/appreciation";
import { dateFormat } from "@/utils/date";
import { getSchoolFiller } from "@/api/school";
import orderXpInfo from "@/components/orderXpInfo.vue";
import orderSpInfo from "@/components/orderSpInfo.vue";
import cancelTips from "../extend/cancelTips.vue";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import axios from "axios";
import { getToken } from "@/utils/auth";

export default {
  name: "incrementNewOrderList",
  components: { orderXpInfo, orderSpInfo, cancelTips },
  data() {
    return {
      tableLoading: false,
      loading: false,
      dataList: [],
      schools: [],
      allSchools: [],
      search: {
        studentName: "", //姓名
        idNumber: "", //身份证
        mobileNumber: "", //手机号码
        collectedName: "", //采集员
        schoolId: "", //学校ID
        stime: "", //开始时间
        etime: "", //结束时间
        orderNo: "", //订单号
        shotTypeCode: "",
      },
      pageConfig: {
        currentPage: 1,
        total: 0,
        pageSize: 20,
      },
      //确认完成
      orderId: null,
      dlgImportVisible: false,
      uploadLoading: false,
      fileForm: {
        isFaceDetect: null,
        file: null,
      },
      hasFile: false,
      fileList: [],
      photoImg: "../temp/none_img.jpg",
      uploadImg: "../temp/none_img.jpg",
    };
  },
  methods: {
    //订单列表
    funcQueryOrderList() {
      const params = this.search;
      this.tableLoading = true;
      // const load = this.$loading({
      //   lock: true,
      //   text: "数据加载中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(255, 255, 255, 0.1)",
      // });
      queryOrderList(
        params,
        this.pageConfig.pageSize,
        this.pageConfig.currentPage
      )
        .then((resp) => {
          this.tableLoading = false;
          // load.close();
          if (resp.code == 0) {
            this.dataList = resp.data.content;
            this.pageConfig.total = resp.data.totalElements;
          }
        })
        .catch(() => {
          this.tableLoading = false;
          // load.close();
        });
    },
    //格式化时间
    columnDateFormat(row, column) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    //学校清空
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = this.allSchools; //还原
      }
    },
    //查询学校
    funcQuerySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    //查询
    handleSearch() {
      this.pageConfig.currentPage = 1;
      this.funcQueryOrderList();
    },
    //数据导出
    handleExport() {
      exportAppreciationNewOrders(this.search).then((res) => {
        if (res.code == 0) {
          let link = document.createElement("a");
          link.style.display = "none";
          let fileName = "增值业务-" + Date.parse(new Date()) + ".rar";
          link.href = res.data + "&filename=" + fileName;

          link.download = fileName;
          // console.log(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      });
    },
    //重置
    resetSearch() {
      this.search = {
        studentName: "", //姓名
        idNumber: "", //身份证
        mobileNumber: "", //手机号码
        collectedName: "", //采集员
        schoolId: "", //学校ID
        stime: "", //开始时间
        orderNo: "", //订单号
        etime: "", //结束时间
        shotTypeCode: "",
      };
      this.pageConfig.currentPage = 1;
      this.funcQueryOrderList();
    },
    //设置分页数量
    handleSizeChange(val) {
      this.pageConfig.pageSize = val;
      this.funcQueryOrderList();
    },
    pageReload() {
      this.funcQueryOrderList();
    },
    //上下页
    handleCurrentChange(val) {
      this.pageConfig.currentPage = val;
      this.funcQueryOrderList();
    },
    //查看订单详情
    clickViews(orderId, shotTypeCode) {
      if (shotTypeCode == 0) {
        if (this.$refs.orderInfo != undefined) {
          this.$refs.orderInfo.initData(orderId);
        }
      } else {
        if (this.$refs.orderSpInfo != undefined) {
          this.$refs.orderSpInfo.initData(orderId);
        }
      }
    },
    //取消订单
    clickCancelOrder(id) {
      if (this.$refs.cancelTips != undefined) {
        this.$refs.cancelTips.initData(id);
      }
    },

    handleChange(file) {
      this.uploadImg = file.url;
      this.fileForm.file = file;
    },
    //确认完成
    clickOk(data) {
      this.orderId = data.id;
      if (this.$refs.fileForm != undefined) {
        //重置表单信息
        this.$refs.fileForm.resetFields();
      }
      this.dlgImportVisible = true;
      this.fileList = [];
      this.uploadImg = "../temp/none_img.jpg";
      this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
      this.getPhoto(data.pathCollectedPhoto);
    },
    submitUpload() {
      if (this.fileForm.isFaceDetect==null) {
        this.$message.error("请选择是否开启人脸识别");
        return;
      }
      if (!this.fileForm.file) {
        this.$message.error("请选择上传照片");
        return;
      }
      this.uploadLoading = true;
      let dataPar = this.fileForm;
      let fdParams = new FormData();
      fdParams.append("file", dataPar.file.raw);
      fdParams.append("isFaceDetect", dataPar.isFaceDetect);
      finishOrder(this.orderId,fdParams).then((resp) => {
        if (resp.code == 0) {
          this.uploadLoading = false;
          this.$message({
            message: resp.message,
            type: "success",
          });
        } else {
          this.$message({
            message: resp.message,
            type: "error",
          });
        }
        this.dlgImportVisible = false;
        this.funcQueryOrderList();
      });
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
            this.isPhoto = true;
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
  mounted() {
    this.funcQueryOrderList();
    this.funcQuerySchools();
  },
};
</script>
<style>
.photo {
  width: 120px;
  height: 160px;
  position: relative;
  vertical-align: top;
  text-align: center;
}
.photo-box .item {
  text-align: center;
}
.photo-box .item .desc {
  line-height: 30px;
  text-align: center;
}
.photo-box .item .photo {
  margin-right: 0;
}
</style>
